import { date } from '@koolumbus/shared/utils';
import { useToast } from '@koolumbus/web/ui';
import { createTxs, useToggle } from '@koolumbus/web/utils';
import React, { useMemo, useState } from 'react';
import { Modifier } from 'react-day-picker';
import { useMediaQuery } from 'react-responsive';
import { DatesRange } from '../../../lib/state/search-state.store';
import { QueryOutput } from '../../../lib/trpc';
import DatesPickerModal, { DatesPickerModalProps } from './DatesPickerModal';
import FullscreenDatesPickerModal from './FullscreenDatesPickerModal';

export type DatesPickerValue = {
  startDate: Date | null;
  endDate: Date | null;
};

export interface DatesPickerProps {
  value: DatesRange | undefined;
  onChange: (value: DatesRange | undefined) => void;
  renderInput: (params: { openPicker: () => void }) => JSX.Element;
  unavailableRanges?: QueryOutput['properties']['availability']['bookings'];
  isResettable?: boolean;
}

const DatesPicker: React.FC<React.PropsWithChildren<DatesPickerProps>> = ({
  value,
  onChange: handleChange,
  renderInput,
  unavailableRanges,
  isResettable = true,
}) => {
  const tx = useTxs();
  const toast = useToast();
  const isFloating = useMediaQuery({ minWidth: 420 });
  const [isOpen, { off: onClose, on: onOpen }] = useToggle();

  const [{ startDate, endDate }, setDatesValue] = useState<DatesPickerValue>({
    startDate: value?.dateFrom ?? null,
    endDate: value?.dateTo ?? null,
  });

  const onChange = (value: DatesPickerValue) => {
    if (value.startDate && value.endDate && date(value.endDate).diff(value.startDate, 'd') < 30) {
      toast.warning(tx.minimumStayLen);
    }

    setDatesValue(value);
  };

  const onConfirm = () => {
    if (startDate && endDate) {
      handleChange({ dateFrom: startDate, dateTo: endDate });
    }

    onClose();
  };

  const onReset = () => {
    handleChange(undefined);
    setDatesValue({ startDate: null, endDate: null });
    onClose();
  };

  const disabledDays = useMemo(() => {
    const modifiers: Modifier[] = [];

    if (unavailableRanges) {
      if (startDate && !endDate) {
        let firstCheckIn: Date | null = null;

        for (const range of unavailableRanges) {
          if (
            date(range.checkIn).isAfter(startDate) &&
            (firstCheckIn === null || date(range.checkIn).isBefore(firstCheckIn))
          ) {
            firstCheckIn = range.checkIn;
          }

          if (date(range.checkOut).isSameOrBefore(startDate, 'd')) {
            modifiers.push({ after: range.checkIn, before: range.checkOut });
          }
        }

        if (firstCheckIn) {
          modifiers.push({ after: firstCheckIn });
        }
      } else {
        for (const range of unavailableRanges) {
          modifiers.push({ after: range.checkIn, before: range.checkOut });
        }
      }
    }

    return modifiers;
  }, [endDate, startDate, unavailableRanges]);

  const canSelectRange = (start: Date, end: Date) => {
    if (date(start).isSameOrAfter(end, 'd')) {
      return false;
    }

    if (date(start).isSameOrBefore(new Date(), 'd')) {
      return false;
    }

    if (unavailableRanges) {
      for (const range of unavailableRanges) {
        if (date(range.checkIn).isBefore(end, 'd') && date(range.checkOut).isAfter(start, 'd')) {
          return false;
        }
      }
    }

    return true;
  };

  const modalProps: DatesPickerModalProps = {
    isOpen,
    onClose,
    value: { startDate, endDate },
    onChange,
    onReset: isResettable ? onReset : undefined,
    onSubmit: onConfirm,
    isSubmitDisabled: !(startDate && endDate),
    disabledDays,
    canSelectRange,
  };

  return (
    <>
      {renderInput({ openPicker: onOpen })}

      {isFloating ? (
        <DatesPickerModal {...modalProps} />
      ) : (
        <FullscreenDatesPickerModal {...modalProps} />
      )}
    </>
  );
};

const useTxs = createTxs({
  en: {
    minimumStayLen: 'The minimum stay length for our apartments is one month',
    actions: {
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
  },
  it: {
    minimumStayLen: 'La durata minima di affitto per i nostri appartamenti è di un mese',
    actions: {
      cancel: 'Annulla',
      confirm: 'Conferma',
    },
  },
});

export default DatesPicker;
