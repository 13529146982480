import { Dialog, Transition } from '@headlessui/react';
import { date } from '@koolumbus/shared/utils';
import clsx from 'clsx';
import React, { Fragment, useRef } from 'react';
import { DatesPickerValue } from './DatesPicker';
import DatesPickerDialogActions from './DatesPickerDialogActions';
import DatesPickerRangeShortcuts from './DatesPickerRangeShortcuts';
import InlineDatesRangePicker, { InlineDatesRangePickerProps } from './InlineDatesPicker';

export interface DatesPickerModalProps
  extends Pick<InlineDatesRangePickerProps, 'disabledDays' | 'modifiers'> {
  isOpen: boolean;
  value: DatesPickerValue;
  onChange: (value: DatesPickerValue) => void;
  onReset?: () => void;
  onClose: () => void;
  onSubmit: () => void;
  isSubmitDisabled: boolean;
  canSelectRange: (start: Date, end: Date) => boolean;
}

const DatesPickerModal: React.FC<React.PropsWithChildren<DatesPickerModalProps>> = ({
  isOpen,
  value,
  onChange,
  onClose,
  onReset,
  onSubmit,
  isSubmitDisabled,
  disabledDays,
  modifiers,
  canSelectRange,
}) => {
  const cancelButtonRef = useRef(null);

  const onShortcutClick = (months: number) => {
    if (value.startDate) {
      onChange({
        startDate: value.startDate,
        endDate: date(value.startDate).add(months, 'M').toDate(),
      });
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={clsx(
                'inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all',
                'sm:my-8 max-w-[350px] md:min-w-[700px]',
              )}
            >
              <div className="flex justify-center bg-white pt-2 h-96">
                <InlineDatesRangePicker
                  value={value}
                  onChange={onChange}
                  disabledDays={disabledDays}
                  modifiers={modifiers}
                />
              </div>

              <div className="pb-6 px-4 md:px-6">
                <DatesPickerRangeShortcuts
                  pickerValue={value}
                  canSelectRange={canSelectRange}
                  onShortcutClick={onShortcutClick}
                />
              </div>

              <div className="bg-gray-50 px-4 py-3 sm:px-6">
                <DatesPickerDialogActions
                  onCancel={onClose}
                  onReset={onReset}
                  onSubmit={onSubmit}
                  isSubmitDisabled={isSubmitDisabled}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DatesPickerModal;
