import styled from '@emotion/styled';
import { Dialog, Transition } from '@headlessui/react';
import { date } from '@koolumbus/shared/utils';
import { Button } from '@koolumbus/web/ui';
import React, { Fragment, useState } from 'react';
import DatesPickerDialogActions from './DatesPickerDialogActions';
import { DatesPickerModalProps } from './DatesPickerModal';
import DatesPickerRangeShortcuts from './DatesPickerRangeShortcuts';
import InlineDatesRangePicker from './InlineDatesPicker';

interface FullscreenDatesPickerModalProps extends DatesPickerModalProps {}

const FullscreenDatesPickerModal: React.FC<
  React.PropsWithChildren<FullscreenDatesPickerModalProps>
> = ({
  isOpen,
  onClose,
  value,
  onChange,
  onReset,
  onSubmit,
  isSubmitDisabled,
  disabledDays,
  modifiers,
  canSelectRange,
}) => {
  const [monthsToDisplay, setMonthsToDisplay] = useState(13);

  const onLoadMoreMonths = () => {
    setMonthsToDisplay((v) => v + 12);
  };

  const onShortcutClick = (months: number) => {
    if (value.startDate) {
      onChange({
        startDate: value.startDate,
        endDate: date(value.startDate).add(months, 'M').toDate(),
      });
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-50" onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-[.98]"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-150"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-[.98]"
            >
              <div className="w-screen">
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  {/* <div className="flex-shrink-0 py-3 px-4 bg-primary text-center">
                    <p className="text-white font-semibold">Pick your stay dates</p>
                  </div> */}

                  <div className="min-h-0 flex-1 flex flex-col pb-6 overflow-y-scroll">
                    <div className="relative flex-1">
                      <InlineDatesRangePicker
                        vertical
                        value={value}
                        onChange={onChange}
                        monthsToDisplay={monthsToDisplay}
                        disabledDays={disabledDays}
                        modifiers={modifiers}
                      />

                      <div className="flex justify-center my-6">
                        <Button onClick={onLoadMoreMonths}>Load more</Button>
                      </div>
                    </div>
                  </div>

                  <div className="flex-shrink-0 pt-3 pb-4 divide-y bg-white">
                    <ShortcutsWrapper className="px-4 pb-2 pt-1 inline-block w-full whitespace-nowrap overflow-x-auto">
                      <DatesPickerRangeShortcuts
                        pickerValue={value}
                        canSelectRange={canSelectRange}
                        onShortcutClick={onShortcutClick}
                      />
                    </ShortcutsWrapper>

                    <div className="px-4 pt-4">
                      <DatesPickerDialogActions
                        onCancel={onClose}
                        onReset={onReset}
                        onSubmit={onSubmit}
                        isSubmitDisabled={isSubmitDisabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const ShortcutsWrapper = styled('div')`
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default FullscreenDatesPickerModal;
