import { date } from '@koolumbus/shared/utils';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import type { DayPickerProps, Modifier, Modifiers, NavbarElementProps } from 'react-day-picker';
import DayPicker from 'react-day-picker';
import { DatesPickerValue } from './DatesPicker';

export interface InlineDatesRangePickerProps {
  value: DatesPickerValue;
  onChange: (value: DatesPickerValue) => void;
  vertical?: boolean;
  monthsToDisplay?: number;
  disabledDays?: Modifier[];
  modifiers?: Partial<Modifiers>;
}

const InlineDatesRangePicker: React.FC<React.PropsWithChildren<InlineDatesRangePickerProps>> = ({
  value,
  onChange,
  vertical,
  monthsToDisplay,
  disabledDays,
  modifiers: modifiersProp,
}) => {
  const [hoverDay, setHoverDay] = useState<Date | null>(null);

  const onDayClick: DayPickerProps['onDayClick'] = (day, modifiers) => {
    if (modifiers.disabled) {
      return;
    }

    const { startDate, endDate } = value;

    if ((!startDate && !endDate) || (startDate && endDate) || (!startDate && endDate)) {
      onChange({ startDate: day, endDate: null });
    } else {
      if (date(day).isSame(startDate)) {
        onChange({ startDate: null, endDate: null });
      } else if (date(day).isBefore(startDate!)) {
        onChange({ startDate: day, endDate: startDate });
      } else {
        onChange({ startDate, endDate: day });
      }
    }
  };

  const selectedDays = useMemo<DayPickerProps['selectedDays']>(() => {
    const modifiers: Modifier[] = [];

    switch (true) {
      case Boolean(value.startDate && value.endDate):
        modifiers.push({ from: value.startDate, to: value.endDate });
        break;
      case Boolean(value.startDate && hoverDay):
        modifiers.push({ from: value.startDate, to: hoverDay });
        break;
      case Boolean(value.startDate):
        modifiers.push(value.startDate!);
        break;
    }

    return modifiers;
  }, [hoverDay, value.endDate, value.startDate]);

  const modifiers = useMemo(() => {
    const modifiers: Partial<Modifiers> = {};

    if (value.startDate) {
      modifiers['selection-start'] = value.startDate;
    }

    if (value.endDate) {
      modifiers['selection-end'] = value.endDate;
    }

    return modifiers;
  }, [value.endDate, value.startDate]);

  const props: DayPickerProps = {
    selectedDays,
    disabledDays: [{ before: date().add(1, 'd').toDate() }, ...(disabledDays ?? [])],
    modifiers: { ...modifiers, ...modifiersProp },
    onDayClick,
    onDayMouseEnter: (day) => setHoverDay(day),
    onDayMouseLeave: () => setHoverDay(null),
    showOutsideDays: true,
    navbarElement: vertical ? undefined : Navbar,
  };

  return (
    <>
      <div className="hidden md:flex min-w-[680px]">
        <DayPicker {...props} numberOfMonths={2} />
      </div>

      <div className="block md:hidden">
        <DayPicker {...props} numberOfMonths={vertical ? monthsToDisplay ?? 13 : 1} />
      </div>
    </>
  );
};

const Navbar: React.FC<React.PropsWithChildren<NavbarElementProps>> = ({
  onPreviousClick,
  onNextClick,
  className,
}) => {
  const btnClass =
    'inline-flex items-center px-4 py-1 border border-gray-300 shadow-sm text-lg font-semibold rounded-md text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary';

  return (
    <div className={clsx(className, 'absolute top-0 right-0 space-x-2 px-4 pt-2 md:px-2')}>
      <button type="button" onClick={() => onPreviousClick()} className={btnClass}>
        ←
      </button>

      <button type="button" onClick={() => onNextClick()} className={btnClass}>
        →
      </button>
    </div>
  );
};

export default InlineDatesRangePicker;
