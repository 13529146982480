import { date } from '@koolumbus/shared/utils';
import { createTxs } from '@koolumbus/web/utils';
import clsx from 'clsx';
import { DatesPickerValue } from './DatesPicker';

interface DatesPickerRangeShortcutsProps {
  pickerValue: DatesPickerValue;
  onShortcutClick: (months: number) => void;
  canSelectRange: (start: Date, end: Date) => boolean;
}

const DatesPickerRangeShortcuts: React.FC<
  React.PropsWithChildren<DatesPickerRangeShortcutsProps>
> = ({ pickerValue, onShortcutClick, canSelectRange }) => {
  const tx = useTxs();

  const isEnabled = (months: number) => {
    if (!pickerValue.startDate) {
      return false;
    }

    return canSelectRange(
      pickerValue.startDate,
      date(pickerValue.startDate).add(months, 'M').toDate(),
    );
  };

  return (
    <div className="flex gap-2">
      {[1, 3, 6, 12].map((months) => (
        <RangeShortcutButton
          key={months}
          onClick={() => onShortcutClick(months)}
          label={tx.label(months)}
          isEnabled={isEnabled(months)}
        />
      ))}
    </div>
  );
};

interface RangeShortcutButtonProps {
  onClick: () => void;
  label: string;
  isEnabled: boolean;
}

const RangeShortcutButton: React.FC<React.PropsWithChildren<RangeShortcutButtonProps>> = ({
  onClick,
  label,
  isEnabled,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={!isEnabled}
      className={clsx(
        'inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 rounded-md text-gray-600 bg-white',
        'hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary',
        'disabled:opacity-50 disabled:pointer-events-none',
      )}
    >
      {label}
    </button>
  );
};

const useTxs = createTxs({
  en: {
    label: (months: number) => `${months} ${months === 1 ? 'month' : 'months'}`,
  },
  it: {
    label: (months: number) => `${months} ${months === 1 ? 'mese' : 'mesi'}`,
  },
});

export default DatesPickerRangeShortcuts;
