import { createTxs } from '@koolumbus/web/utils';
import clsx from 'clsx';

interface DatesPickerDialogActionsProps {
  onReset?: () => void;
  onCancel: () => void;
  onSubmit: () => void;
  isSubmitDisabled: boolean;
}

const DatesPickerDialogActions: React.FC<
  React.PropsWithChildren<DatesPickerDialogActionsProps>
> = ({ onReset, onCancel, onSubmit, isSubmitDisabled }) => {
  const tx = useTxs();

  return (
    <div className="flex justify-between">
      <button
        type="button"
        onClick={onReset}
        disabled={typeof onReset === 'undefined'}
        className={clsx(
          'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white',
          'hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:pointer-events-none',
        )}
      >
        Reset
      </button>

      <div className="flex space-x-2">
        <button
          type="button"
          onClick={onCancel}
          className={clsx(
            'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white',
            'hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
          )}
        >
          {tx.cancel}
        </button>

        <button
          type="button"
          disabled={isSubmitDisabled}
          onClick={onSubmit}
          className={clsx(
            'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-400',
            'hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50',
          )}
        >
          {tx.confirm}
        </button>
      </div>
    </div>
  );
};

const useTxs = createTxs({
  en: {
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
  it: {
    cancel: 'Annulla',
    confirm: 'Conferma',
  },
});

export default DatesPickerDialogActions;
